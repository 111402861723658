<template>
  <div
    v-if="userObj.UserGroupId==1"
    class="demo-inline-spacing"
  >
    <b-row>
      <b-col class="mr-2">
        <statistic-card-vertical
          icon="UsersIcon"
          color="success"
          :statistic="summary.aktifAday"
          statistic-title="Aktif Aday Sayısı"
        />
        <statistic-card-vertical
          icon="LayersIcon"
          color="success"
          :statistic="summary.aktifFirma"
          statistic-title="İşveren Sayısı"
        />
        <statistic-card-vertical
          icon="UserMinusIcon"
          color="warning"
          :statistic="summary.eslesmisAday"
          statistic-title="Eşleştirilmiş Aday Sayısı"
        />
        <statistic-card-vertical
          icon="UserPlusIcon"
          color="success"
          :statistic="summary.yonlendirilmisAday"
          statistic-title="Yönlendirilmiş Aday Sayısı"
        />
        <statistic-card-vertical
          icon="UserMinusIcon"
          color="warning"
          :statistic="summary.bekleyenAday"
          statistic-title="Beklemede Olan Aday Sayısı"
        />
        <statistic-card-vertical
          icon="ZapOffIcon"
          color="secondary"
          :statistic="summary.kapaliTalep"
          statistic-title="Kapatılmış Talep Sayısı"
        />
      </b-col>
      <b-col class="mr-2">
        <statistic-card-vertical
          icon="UsersIcon"
          color="secondary"
          :statistic="summary.pasifAday"
          statistic-title="Pasif Aday Sayısı"
        />
        <statistic-card-vertical
          icon="LayersIcon"
          color="success"
          :statistic="summary.aktifTalep"
          statistic-title="Aktif Talep Sayısı"
        />
        <statistic-card-vertical
          icon="LayersIcon"
          color="primary"
          :statistic="summary.eslesmisTalep"
          statistic-title="Eşleştirilmiş Talep Sayısı"
        />
        <statistic-card-vertical
          icon="LayersIcon"
          color="success"
          :statistic="summary.yonlendirilmisTalep"
          statistic-title="Yönlendirilmiş Talep Sayısı"
        />
        <statistic-card-vertical
          icon="UserXIcon"
          color="danger"
          :statistic="summary.olumsuzAday"
          statistic-title="Olumsuz Aday Sayısı"
        />
      </b-col>
      <b-col class="mr-2">
        <statistic-card-vertical
          icon="UsersIcon"
          color="primary"
          :statistic="summary.toplamAday"
          statistic-title="Toplam Aday Sayısı"
        />
        <statistic-card-vertical
          icon="LayersIcon"
          color="secondary"
          :statistic="summary.pasifTalep"
          statistic-title="Pasif Talep Sayısı"
        />
        <statistic-card-vertical
          icon="LayersIcon"
          color="warning"
          :statistic="summary.eslesmemisTalep"
          statistic-title="Eşleştirilmemiş Talep Sayısı"
        />
        <statistic-card-vertical
          icon="LayersIcon"
          color="success"
          :statistic="summary.yonlendirilmemisTalep"
          statistic-title="Yönlendirilmemiş Talep Sayısı"
        />
        <statistic-card-vertical
          icon="UserCheckIcon"
          color="success"
          :statistic="summary.istihdamEdilmisAday"
          statistic-title="İstihdam Edilmiş Aday Sayısı"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import * as auth from '@/utils/auth'
import * as apiReport from '@/api/islemler/reports'
import StatisticCardVertical from '@core/components/statistics-cards/StatisticCardVertical.vue'

export default {
  components: {
    BRow,
    BCol,
    StatisticCardVertical,
  },
  data() {
    return {
      userObj: {},
      summary: {},
    }
  },
  mounted() {
    this.userObj = auth.getUserObject()
    this.GetMainSummary()
  },
  beforeCreate() {
    this.userObj = auth.getUserObject()
  },
  methods: {
    GetMainSummary() {
      if (this.userObj.UserGroupId === '1') {
        apiReport.GetMainSummary().then(res => {
          this.summary = res.data.entity
        })
      }
    },
  },
}
</script>

<style>

</style>
